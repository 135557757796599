<template>
  <div class="content">
    <div class="card-item">
      <template v-if="list && list.length">
        <div class="card" v-for="(item, index) in list" :key="index">
          <div class="card-content">
            <div class="company ellipsis1">
              <span class="title">Company：</span>
              <template v-if="item.exhibitor">
                {{ item.exhibitor.company ? item.exhibitor.company.nameEn : "" }}
              </template>
            </div>
            <div class="product ellipsis1"><span class="title">Product：</span>{{ item.productName }}</div>
            <div class="number ellipsis1"><span class="title">Quantity：</span>{{ item.amount }}</div>
            <div class="remarks">
              <span class="title">Remarks：</span>
              <div class="remark-text" :title="item.remark">
                {{ item.remark }}
              </div>
            </div>
            <div class="state">
              <template v-if="item.auditState == 1">
                <span class="read" v-if="item.state == 1">Read</span>
                <span v-else>Unread</span>
              </template>
              <template v-else>
                <span v-if="item.auditState == -1">Audit failure</span>
                <span class="sh" v-else>Under review</span>
              </template>
              <div class="sta_right" v-if="item.auditState == -1">
                <Poptip confirm title="Are you sure to delete this?" @on-ok="delItem(item.id)">
                  <div class="delete">Delete</div>
                </Poptip>
                <div class="edit" @click="editItem(item)">
                  Edit
                </div>
              </div>
              <div class="sta_right time" v-else>{{ item.createAt }}</div>
            </div>
          </div>
        </div>
      </template>
      <div class="no_more mar_bot2" v-if="loading">
        <p>{{ $t("lang.loading") }}</p>
      </div>
      <div class="no_more mar_bot2" v-else-if="finished">
        <p>{{ $t("lang.Its_the_end") }}</p>
      </div>
      <div class="more mar_bot2" @click="moreClick" v-else-if="list && list.length">
        <p>{{ $t("lang.loadMore") }}</p>
      </div>
      <div class="no_more mar_bot2" v-else>{{ $t("lang.No_data") }}！</div>
    </div>
    <inquiryModal :isEdit="true" ref="inquiryModal" @finish="getData"></inquiryModal>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import inquiryModal from "@components/inquiryModal";
export default {
  name: "layout",
  data() {
    return {
      title: "",
      list: [],
      loading: false,
      finished: true,
      pageIndex: 1,
      pageSize: 15,
    };
  },
  components: {
    inquiryModal,
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      user: "getUser",
    }),
  },
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXSEn",
      delEnquiryNew: "user/delEnquiryNew",
    }),
    async getData() {
      this.loading = true;
      let query = `
        query($query:QueryInput!){
          exhibitorNewEnquiryQuery{
            query(query:$query){
                hasNextPage
                pageIndex
                totalCount
                items {
                  amount
                  auditRemark
                  auditState
                  auditTime
                  auditUser
                  createAt
                  exhibitionId
                  exhibitor{
                    id
                    company{
                      name
                      nameEn
                    }
                  }
                  id
                  productId
                  productName
                  remark
                  state
                  stateAt
                  userId
                }
              }
            }
        }
      `;
      let where = {
        Equal: {
          n: "userId",
          v: this.user.userId,
        },
        EqualA: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
      };
      let order = [
        {
          N: "createAt",
          v: 0,
        },
      ];
      let opt = {
        query: query,
        variables: {
          query: {
            page: this.pageIndex,
            size: this.pageSize,
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
        },
      };
      let data = await this.graphqlPost(opt);
      let res = JSON.parse(JSON.stringify(data.data.exhibitorNewEnquiryQuery.query));
      res.items.forEach((e) => {
        e.createAt = moment(e.createAt).format("YYYY/MM/DD HH:mm:ss");
        if (e.imgUrls) {
          e.imgUrls = e.imgUrls.split(";");
        }
      });
      if (this.pageIndex == 1) {
        this.list = res.items;
      } else {
        this.list = [...new Set([...this.list, ...res.items])];
      }
      this.loading = false;
      if (!res.hasNextPage) {
        this.finished = true;
      }
    },
    //点击加载更多
    moreClick() {
      if (this.finished) return;
      this.pageIndex += 1;
      this.getData();
    },
    editItem(e) {
      console.log(e, "chong");
      let opt = {
        remark: e.remark,
        id: e.id,
        amount: e.amount,
      };
      this.$refs.inquiryModal.open(opt);
    },
    async delItem(id) {
      let res = await this.delEnquiryNew(id);
      if (res.result) {
        this.getData();
        this.$Message.success("Deletion succeeded");
      } else {
        this.$Message.error(res.message);
      }
      console.log(res, "");
    },
  },
};
</script>

<style lang="scss" scoped>
.ellipsis1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@import "@assets/scss/my-theme/mixin.scss";
.content {
  min-height: 700px;
  background: #ffffff;
  .card-item {
    display: flex;
    flex-wrap: wrap;
  }
  .card {
    width: 310px;
    height: 204px;
    background-color: #f7f7f7;
    border-radius: 3px;
    margin-left: 20px;
    margin-top: 20px;
    &-content {
      padding: 10px;
      span {
        color: #666;
        font-size: 14px;
      }
    }
    .company {
      margin-bottom: 8px;
    }
    .product {
      margin-bottom: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .number {
      margin-bottom: 8px;
    }
    .remarks {
      display: flex;
      height: 65px;
      margin-bottom: 8px;
      .title {
        display: block;
        white-space: nowrap;
      }
      .remark-text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
    }
    .state {
      margin-top: 8px;
      position: relative;
      display: flex;
      align-items: center;
      height: 24px;
      .sta_right {
        margin-left: auto;
        display: flex;
        align-items: center;
        .delete {
          color: #ff5e00;
          font-size: 12px;
          cursor: pointer;
          margin-right: 10px;
        }
        .edit {
          width: 64px;
          height: 24px;
          @include background_color(#1890ff);
          border-radius: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          font-size: 12px;
          cursor: pointer;
        }
      }
      span {
        font-size: 12px;
        color: #666;
        &.sh {
          color: #ff5e00;
        }
        &.read {
          color: #0190fe;
        }
      }
    }
  }
}
</style>
